import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import TicketShop from "components/ticket-shop";
import { TicketshopPage } from "components/TicketshopPage";
import React, { useEffect, useRef } from "react";

const BetterDaysFestivalPage = () => {
  const apiRef = useRef(null);

  useEffect(() => {
    window.fixrApiCallback = (api) => {
      apiRef.current = api;
    };
  });

  return (
    <TicketshopPage
      title="Better Days Festival"
      imageSrc={""}
      description={
        <div>
          <p>
            A multi-stage, all day, 12 hour festival set in the heart of Leeds
            at the incredible Beaver Works Complex. A 3000-person festival of
            dancing, music & entertainment with 2 purpose built outdoor stages
            and a series of indoor areas boasting big soundsystems, festival
            style production and immersive decor. Better Days Festival takes
            over Leeds on both May bank holidays kickstarting a sunkissed season
            of summer loving! Better Days always sells out in advance so be sure
            to grab your tickets as soon as they go live.
          </p>
          <p>Better Days are coming!</p>
        </div>
      }
      backgroundSrc="/images/better-days-festival.jpg"
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?callback=fixrApiCallback&salesChannel=whitelabel-website" data-fixr-shop-id="77358234-320b-48bd-8c0c-bf33aecef89c" data-fixr-theme="light" data-name-filter="better days"></script>`}
    />
  );
};

export default BetterDaysFestivalPage;
